import React from 'react';

import './NEForm.sass';
import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import PropTypes from 'prop-types';
import { NEStepsContainer } from 'components/NEComponents/NEStepsContainer/NEStepsContainer';
import { NEStepEmailInput } from 'components/NEComponents/NESteps/NEStepEmailInput/NEStepEmailInput';
import { NEStepBranchSelection } from 'components/NEComponents/NESteps/NEStepBranchSelection/NEStepBranchSelection';
import { NEStepForm } from 'components/NEComponents/NESteps/NEStepForm/NEStepForm';
import { NEStepFormUpload } from 'components/NEComponents/NESteps/NEStepFormUpload/NEStepFormUpload';

import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';
import {
  parseAEMDatapolicyProps,
  parseAEMSelfDeclarationCompanyFacilityProps,
} from 'utils/AEMPropUtils';
import { NE_ACTIVE_STEP } from 'global/constants';

const NEForm = (props) => {
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={props} name="NewEntryForm" />;
  }
  return (
    <div className="ne-form">
      <div className="ne-form__container">
        <NEStepsContainer>
          <NEStepEmailInput key={NE_ACTIVE_STEP.EMAIL_INPUT} />
          <NEStepBranchSelection key={NE_ACTIVE_STEP.BRANCH_SELECTION} />
          <NEStepForm
            key={NE_ACTIVE_STEP.FORM}
            energyAgreementLink={props.energyAgreementLink}
            gasAgreementLink={props.gasAgreementLink}
          />
          <NEStepFormUpload
            key={NE_ACTIVE_STEP.FORM_UPLOAD}
            expertQualificationCertificateGasLink={props.expertQualificationCertificateGasLink}
            installerContractLinkNNNGLink={props.installerContractLinkNNNGLink}
            installerContractLinkEENGLink={props.installerContractLinkEENGLink}
            installerContractLinkHANSGLink={props.installerContractLinkHANSGLink}
            installerContractLink={props.installerContractLink}
            expertQualificationCertificateEnergyLink={
              props.expertQualificationCertificateEnergyLink
            }
            selfDeclarationCompanyFacilityLink={parseAEMSelfDeclarationCompanyFacilityProps(props)}
            dataPolicyDocuments={parseAEMDatapolicyProps(props)}
            installerContractMeterInstallationGasLink={
              props.installerContractMeterInstallationGasLink
            }
            installerContractMeterInstallationEnergyLink={
              props.installerContractMeterInstallationEnergyLink
            }
            installerContractSealing={props.installerContractSealing}
          />
        </NEStepsContainer>
      </div>
    </div>
  );
};

NEForm.displayName = 'NEForm';

NEForm.propTypes = {
  expertQualificationCertificateGasLink: PropTypes.string,
  expertQualificationCertificateEnergyLink: PropTypes.string,
  installerContractMeterInstallationGasLink: PropTypes.string,
  installerContractMeterInstallationEnergyLink: PropTypes.string,
  installerContractSealing: PropTypes.string,
  installerContractLink: PropTypes.string,
  installerContractLinkEENGLink: PropTypes.string,
  installerContractLinkNNNGLink: PropTypes.string,
  installerContractLinkHANSGLink: PropTypes.string,
  selfDeclarationCompanyFacilityLink: PropTypes.string,
  selfDeclarationCompanyFacilityNNNGLink: PropTypes.string,
  gasAgreementLink: PropTypes.string,
  energyAgreementLink: PropTypes.string,
  datapolicyEDILink: PropTypes.string,
  datapolicyAVALink: PropTypes.string,
  datapolicySHNGLink: PropTypes.string,
  datapolicyBAGLink: PropTypes.string,
  datapolicyEENGLink: PropTypes.string,
  datapolicyHANSGLink: PropTypes.string,
  datapolicyNNNGLink: PropTypes.string,
  datapolicyEDILabel: PropTypes.string,
  datapolicyAVALabel: PropTypes.string,
  datapolicyBAGLabel: PropTypes.string,
  datapolicySHNGLabel: PropTypes.string,
  datapolicyEENGLabel: PropTypes.string,
  datapolicyHANSGLabel: PropTypes.string,
  datapolicyNNNGLabel: PropTypes.string,
};

NEForm.defaultProps = {};

MapTo(['e-fix/components/content/newentry'])(NEForm);

export { NEForm };
