import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { prepFilesForUpload } from 'utils/FileUtil';
import { FORM_CONSTANTS } from 'global/constants';
import './UploadField.sass';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import uploadIcon from '../../assets/pics/uploadIcon.svg';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { getBaseUrl } from 'utils/UrlUtil';
import { HintText } from 'components/HintText/HintText';
import { HINTTEXT_TYPE } from 'components/HintText/HintTextType';
import { isArray } from 'lodash';

export function UploadField(props) {
  const { files, infoText, name, formik, infoLink } = props;

  const [unsupportedFilesCount, setUnsupportedFilesCount] = useState(0);
  const [onDrag, setOnDrag] = useState(false);

  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files).filter((file) =>
      FORM_CONSTANTS.ACCEPTED_FORM_INPUT_FILE_TYPES.includes(file.type)
    );
    setUnsupportedFilesCount(event.target.files.length - selectedFiles.length);

    const newEvent = {
      ...event,
      target: { ...event.target, files: selectedFiles },
      currentTarget: { ...event.currentTarget, files: selectedFiles },
    };
    onFileChange(newEvent);
  };

  const onFileChange = async (event) => {
    const newFiles = event.currentTarget.files;
    if (newFiles) {
      const newFilesB64 = await prepFilesForUpload(newFiles);
      formik.setFieldValue(name, [...files, ...newFilesB64]);
    }
  };

  const onFileRemove = (index) => {
    const newFiles = files.filter((_, iindex) => iindex !== index);
    formik.setFieldValue(name, [...newFiles]);
  };

  const handleDrop = (event) => {
    setOnDrag(false);
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      const selectedFiles = newFiles.filter((file) => {
        return FORM_CONSTANTS.ACCEPTED_FORM_INPUT_FILE_TYPES.includes(file.type);
      });
      const newEvent = {
        ...event,
        target: { ...event.target, files: selectedFiles },
        currentTarget: { ...event.currentTarget, files: selectedFiles },
      };
      onFileChange(newEvent);
    }
  };

  return (
    //too complex to clean up today, might want to refactor the whole component to make it easier to read
    <div className="form-upload__wrapper">
      {infoText && (
        <div className={`info-text`}>
          {infoText}
          {infoLink ? (
            isArray(infoLink) ? (
              infoLink.length === 2 ? (
                <span>
                  ,{' '}
                  <a href={getBaseUrl() + infoLink[1]} download className={'download-item'}>
                    NordNetz GmbH
                  </a>{' '}
                  und{' '}
                  <a href={getBaseUrl() + infoLink[0]} download className={'download-item'}>
                    Schleswig-Holstein Netz GmbH
                  </a>
                </span>
              ) : (
                <span>
                  :{' '}
                  <a href={getBaseUrl() + infoLink[0]} download className={'download-item'}>
                    ElbEnergie GmbH
                  </a>
                  ,{' '}
                  <a href={getBaseUrl() + infoLink[1]} download className={'download-item'}>
                    HanseGas GmbH
                  </a>
                  ,{' '}
                  <a href={getBaseUrl() + infoLink[2]} download className={'download-item'}>
                    NordNetz GmbH
                  </a>{' '}
                  und{' '}
                  <a href={getBaseUrl() + infoLink[3]} download className={'download-item'}>
                    Schleswig-Holstein Netz GmbH
                  </a>
                </span>
              )
            ) : (
              <a href={getBaseUrl() + infoLink} download className={'download-item'}>
                : Link zum Dokument
              </a>
            )
          ) : null}
        </div>
      )}
      <section
        className="drag-drop"
        onDragEnter={() => {
          setOnDrag((val) => {
            return val + 1;
          });
        }}
        onDragLeave={() => {
          setOnDrag((val) => {
            return val - 1;
          });
        }}>
        <input
          type="file"
          ref={inputRef}
          hidden
          id="browse"
          onChange={handleFileChange}
          accept=".pdf, .png, .gif, .jpg, .jpeg"
          multiple
        />
        <div
          className={`document-uploader ${
            files.length > 0 && onDrag == 0
              ? 'upload-box active'
              : onDrag
              ? 'upload-box drag'
              : 'upload-box'
          }`}
          onDrop={handleDrop}
          onClick={() => {
            inputRef.current.click();
          }}
          onDragOver={(event) => event.preventDefault()}>
          <div className="upload-info">
            <img className="svg" src={uploadIcon}></img>
            <div>
              <p>
                Dateien hier per Drag-and-Drop einfügen oder zum <a>Hochladen anklicken</a>
              </p>
            </div>
          </div>

          {files.length > 0 && (
            <div className="file-list">
              {files.map((file, index) => (
                <div className="file-item" key={index}>
                  <InsertDriveFileOutlinedIcon fontSize="large" />
                  <div className="file-item-bottom">
                    <div className="file-info">{file.name}</div>
                    <div className="file-actions">
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          onFileRemove(index);
                        }}>
                        <DeleteForeverOutlinedIcon />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {unsupportedFilesCount > 0 && (
        <div className="form-upload__warning-message-unsupported">
          <HintText
            type={
              HINTTEXT_TYPE.ERROR
            }>{`Nicht unterstützte Dateitypen wurden gefiltert (${unsupportedFilesCount} Datein(en))`}</HintText>
        </div>
      )}
    </div>
  );
}
UploadField.propTypes = {
  infoText: PropTypes.node,
  name: PropTypes.string,
  files: PropTypes.array.isRequired,
  formik: PropTypes.object,
  infoLink: PropTypes.string,
};
